html {
    scroll-behavior: smooth;
}

body {
    font-family     : 'Open Sans', sans-serif !important;
    line-height     : 1.5;
    color           : #000000;
    overflow-x      : hidden;
    background-color: #EAEAEA;
    /* display      : flex !important; */
    align-items     : center !important;
    min-height      : 100vh !important;
    padding         : 1rem 0.5vw !important;
}

.bg_main {
    background-color: #F5F5F5;
    min-height      : 96vh;
    border-radius   : 20px;
    /* min-width    : 99vw !important; */
    /* margin-left  : 0.5vw !important;
    margin-right    : 0.5vw !important; */
    /* position     : absolute;
    top             : 0;
    bottom          : 0;
    left            : 0;
    right           : 0; */
    /* margin       : 1rem 0.5vw !important; */
}

.bg_main>div {
    min-height: 96vh;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    width    : 98% !important;
    max-width: 98% !important;
}

a {
    color: #000000;
}

a:hover {
    color: #FFA41E;
}

h1,
h2,
h3 {
    margin     : 0;
    line-height: 1.5 !important;
    font-family: 'Ubuntu', sans-serif !important;
}

p {
    margin   : 0 !important;
    font-size: 1rem;
}

button {
    font-family   : 'Open Sans', sans-serif !important;
    text-transform: uppercase;
}

.text-justify {
    text-align: justify;
}

.text_orange {
    color: #FFA41E;
}

.bg_white {
    background-color: #FFFFFF;
}

.h_100 {
    height: 100vh !important;
}

/* .w-100 {
    min-width: 100vw !important;
    width: 100vw !important;
} */

/* .container.w-100 {
    min-width: 98vw !important;
    width: 98vw !important;
} */


.card-header:first-child {
    z-index: 999;
}

.oi:empty:before {
    width     : 1rem;
    text-align: center;
    box-sizing: content-box;
}

.oi-chevron-right:before {
    content: '►';
}

.oi-chevron-bottom:before {
    content: '▼';
}

.text_big {
    font-size: 150%;
}

.text-small {
    font-size: 70%;
}

.bg_danger {
    background-color: #FBF4F5 !important;
}

.bg_warning {
    background-color: #FFF8EF !important;
}

.bg_success {
    background-color: #DCF3EB !important;
}

.bg_info {
    background-color: #E1F2FC !important;
}

.bg_danger p {
    color: #F53E39 !important;
}

.bg_warning p {
    color: #ffc107 !important;
}

.bg_success p {
    color: #28a745 !important;
}

.bg_info p {
    color: #08A0F7 !important;
}

.active_icon svg {
    color: #FFA41E !important;
}

.bg_orange {
    background-color: #FFA41E !important;
    color           : #FFFFFF;
}

.bg_gray {
    background-color: #F5F5F5 !important;
}

.border-radius {
    border-radius: 5px !important;
}

.border-radius-0 {
    border-radius: 0 !important;
}

/* SCROLLBAR */
::-webkit-scrollbar {
    width           : 10px;
    background-color: none;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background   : #FFA41E;
}

@-moz-document url-prefix() {
    .scroller {
        scrollbar-color: #FFA41E #FFA41E;
        scrollbar-width: thin;
    }
}

/* pagination */
.page-item.active .page-link {
    z-index         : 3;
    color           : #fff;
    background-color: #FFA41E;
    border-color    : #FFA41E;
}

.page-link:focus {
    box-shadow: 0 0 0 0.2rem rgb(255 164 30 / 25%);
}

.page-link {
    color: #000;
}

/* NAVBAR */
@media (max-width: 992px) {
    .itemMenu {
        font-size: 10px;
    }
}
.activeItem {
    color              : #FFA41E !important;
    /* background-color: #FFA41E !important; */
}

/* BUTTONS */
.swal2-styled.swal2-confirm {
    background-color: #FFA41E !important;
}

.swal2-styled.swal2-default-outline:focus {
    box-shadow: 0 0 0 3px rgb(255 164 30 / 50%) !important;
}

.swal2-styled.swal2-deny {
    background-color: #6C757D !important;
}

.swal2-styled.swal2-deny:focus {
    box-shadow: 0 0 0 3px rgb(108 117 125 / 50%) !important;
}

.btn {
    color : #000000;
    border: 1px solid transparent;
}

.btn-warning {
    color           : #FFFFFF;
    background-color: #FFA41E !important;
    border-color    : #FFA41E !important;
    border-radius   : 100px;
}

.borderRadiusNone {
    border-radius: 5px !important;
}

.btn-warning:hover {
    color           : #FFFFFF;
    background-color: #FF9A04;
    border-color    : #FF9A04;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle,
.btn-warning.focus,
.btn-warning:focus {
    color           : #FFFFFF;
    background-color: #FF9A04;
    border-color    : #FF9A04;
}

/* SIDEBAR */
.buttonsSide {
    display         : inline-block;
    width           : 100%;
    font-weight     : 400;
    color           : #FFFFFF;
    text-align      : center;
    vertical-align  : middle;
    background-color: rgba(46, 51, 54, 0.7);
    border          : 1px solid transparent;
    padding         : 1rem .75rem;
    font-size       : 1rem;
    line-height     : 1.5;
    border-radius   : 1rem;
    text-transform  : uppercase;
    transition      : color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.buttonsSide:hover {
    color          : white;
    text-decoration: none;
}


.card {
    border-radius: 25px !important;
}

.card .icon {
    background-color: #F53E39;
    border-radius   : 50%;
    padding         : 12px;
    width           : 1em !important;
    height          : 1em !important;
    color           : #FFFFFF;
}

.card .icon.warning {
    background-color: #ffc107 !important;
    border-radius   : 50%;
}

.card .icon.success {
    background-color: #28a745 !important;
    border-radius   : 50%;
}

.card .icon.info {
    background-color: #08A0F7 !important;
    border-radius   : 50%;
}

.dropdown-menu {
    width: 100%;
}

.side.form-control {
    display         : inline-block;
    width           : 100%;
    font-weight     : 400;
    height          : 60px;
    color           : #FFFFFF;
    text-align      : center;
    vertical-align  : middle;
    background-color: rgba(46, 51, 54, 0.7);
    border          : 1px solid transparent;
    padding         : 1rem .75rem !important;
    font-size       : 1rem;
    line-height     : 1.5;
    border-radius   : 1rem;
    text-transform  : uppercase;
    transition      : color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.dropdown-toggle {
    border-left: 1px solid white;
}

.side.options a,
.side.dropdown button {
    display         : inline-block;
    width           : 100%;
    font-weight     : 400;
    color           : #FFFFFF;
    text-align      : center;
    vertical-align  : middle;
    background-color: rgba(46, 51, 54, 0.7);
    border          : 1px solid transparent;
    padding         : 1rem .75rem;
    font-size       : 1rem;
    line-height     : 1.5;
    border-radius   : 1rem;
    text-transform  : uppercase;
    transition      : color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.side.options a:hover,
.side.options a:focus,
.side.dropdown button:hover,
.side.dropdown button:focus {
    background-color: #FFA41E;
    transition      : color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    text-decoration : none;
    color           : #FFFFFF;
}

.side.options a.active_btn {
    background-color: #FFA41E;
}

.side.btn-secondary:not(:disabled):not(.disabled).active,
.side.btn-secondary:not(:disabled):not(.disabled):active,
.side.show>.btn-secondary.dropdown-toggle {
    color           : #FFFFFF;
    background-color: #FFA41E !important;
    border-color    : #FFA41E !important;
}

.side.dropdown button {
    text-align: start;
}

.side.dropdown button::after {
    right   : 0.75rem;
    position: absolute;
    top     : 1.1rem;
    content : '\25BC';
    border  : 0;
}

.side.dropdown-menu a {
    color           : initial;
    background-color: initial;
    border-radius   : 0;
}

/* FORMS */
::placeholder {
    color  : #000000;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #000000;
}

::-ms-input-placeholder {
    color: #000000;
}

.form-control {
    border-radius   : 10px;
    background-color: #F1F1F1;
    border-color    : transparent;
}

.form-control:focus {
    color           : #000000;
    background-color: #F1F1F1;
    border-color    : #FF9A04;
    outline         : 0;
    box-shadow      : 0 0 0 0.2rem rgb(222 170 12 / 50%);
}



label {
    color      : #FFA41E;
    font-weight: 700;
}

input[type="file"] {
    display: none;
}

.form-file label {
    width           : 100%;
    height          : 100px;
    border          : 2px dashed #dee2e6;
    display         : flex;
    align-items     : center;
    justify-content : center;
    border-radius   : 20px;
    background-color: #F1F1F1;
}

.min_range_value,
.max_range_value {
    width           : 55px;
    height          : 40px;
    background-color: #6A6D6F;
    color           : #FFFFFF;
    border-radius   : 10px;
    text-align      : center;
    align-items     : center;
    display         : flex;
    justify-content : center;
}

input[type="range"] {
    -webkit-appearance: none;
    width             : 100%;
    height            : 25px;
    background        : #C4C4C4;
    outline           : none;
    opacity           : 0.9;
    -webkit-transition: .2s;
    transition        : opacity .2s;
}

input[type="range"]:hover {
    opacity: 1;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance        : none;
    width             : 30px;
    height            : 30px;
    background        : #FFA41E;
    cursor            : pointer;
    border-radius     : 50%
}

input[type="range"]::-moz-range-thumb {
    width        : 30px;
    height       : 30px;
    background   : #FFA41E;
    cursor       : pointer;
    border-radius: 50%
}

input[type="range"]::-moz-range-progress {
    background-color: #FFA41E;
    height          : 25px;
}

.options .form-check-label {
    color: inherit;
}

.rdrCalendarWrapper {
    font-family: 'Open Sans', sans-serif !important;
}

.rdrCalendarWrapper ::placeholder {
    font-family: 'Open Sans', sans-serif !important;
}

.rdrDateRangeWrapper,
.rdrMonth {
    max-width: 100%;
}

.rdrDateDisplay,
.rdrInRange,
.rdrStartEdge,
.rdrDayEndPreview,
.rdrDayStartPreview,
.rdrEndEdge {
    color: #FFA41E !important;
}

.rdrDayToday .rdrDayNumber span:after {
    background: #FFA41E !important;
}




/* input[type=checkbox] {
    border: 1px solid #C4C4C4;
    height: 16px;
    width: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border-radius: 4px;
    transition-duration: 0.3s;
}

input[type=checkbox]:checked {
    border: 1px solid #FF9A04;
    background-color: #FFFFFF;
}

input[type=checkbox]:checked+:after {
    content: '\2714';
    display: block;
    text-align: center;
    color: #FF9A04;
    position: absolute;
    left: 2px;
    top: 0.5px;
} */

/* CAROUSEL */

.carousel-indicators {
    margin: 0;
}

.carousel-caption {
    position      : initial !important;
    color         : #000000 !important;
    padding-bottom: 40px !important;
    padding-top   : 0 !important;
}

.carousel-indicators li {
    background-color: #FFA41E !important;
    width           : 11px !important;
    height          : 10px !important;
    border-radius   : 50%;
}

/* TABLE */
@media (max-width:992px){
    .dx-g-bs4-toolbar{
        display: block !important;
    }
    .dx-g-bs4-toolbar input[type=text]{
        width:100% !important;
        margin-top: 10px !important;
    }
}

.dx-rg-bs4-table-header-title {
    white-space: pre-wrap !important;
}

table td.position-sticky {
    background-color: #F5F5F5 !important;
}

.hoverTable:hover {
    background-color: rgb(242, 242, 242) !important;
}

.dx-g-bs4-fixed-cell,
.dx-g-bs4-fixed-group-cell {
    /* z-index: 0 !important; */
}

.table-responsive {
    border    : 2px solid #dee2e6;
    border-top: 0 !important;
}

@media screen and (min-width: 768px) {
    .table-responsive {
        border-left : 0;
        border-right: 0;
    }
}

.table {
    margin-bottom: 0;
}

.table thead th {
    color           : #FFA41E;
    text-transform  : capitalize;
    background-color: #F5F5F5 !important;
    border-top      : 0 !important;
    border-bottom   : 0;
    box-shadow      : 0px 2px #dee2e6;
    min-height: 72px;
}

.table td,
.table th,
.table thead td,
.table thead th {
    vertical-align: middle !important;
    white-space   : pre;
}

th {
    vertical-align: middle !important;
}

.table-responsive {
    overflow-y: auto;
    overflow-x: auto;
    max-height: 80vh;
    max-width : 92vw;
}

thead tr th {
    position: sticky;
    top     : 0;
}

.table-responsive .table.products thead tr th:nth-child(1),
.table-responsive .table.products tbody tr td:nth-child(1) {
    position        : sticky;
    left            : 0;
    background-color: #F5F5F5 !important;
    z-index         : 1;
}

.table-responsive .table.products thead tr th:nth-child(2),
.table-responsive .table.products tbody tr td:nth-child(2) {
    position        : sticky;
    left            : 56px;
    background-color: #F5F5F5 !important;
    z-index         : 1;
}

.table-responsive .table.products thead tr th:nth-child(1),
.table-responsive .table.products thead tr th:nth-child(2) {
    top    : 0;
    z-index: 3;
}

.table-responsive .table.products thead tr th:nth-child(2),
.table-responsive .table.products tbody tr td:nth-child(2) {
    box-shadow: 1px 2px #dee2e6;
}

.no-wrap {
    white-space: nowrap;
}

/* POPUP */

.modal-footer .btn-warning {
    border-radius: 0.25rem;
}

.modal-footer .btn-danger {
    color: #FFFFFF;
}

.modal-content {
    background-color: #F5F5F5;
}

/* LOGIN */

.login,
.login a {
    color: #C4C4C4;
}

.login a:hover {
    color: #FF9A04;
}

.login .logo {
    max-width: 100%;
}

.cover_image {
    opacity   : 0.6;
    max-height: 400px;
    width     : auto;
}

@media screen and (min-width: 992px) {
    .bg_register {
        background-image   : linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('./../images/login/login.jpg');
        background-position: center;
        background-repeat  : no-repeat;
        background-size    : cover;
        height             : 96vh;
    }
}

.login .form-control {
    color           : #C4C4C4;
    border-color    : rgba(196, 196, 196, 0.5) !important;
    background-color: #FFFFFF;
    border-radius   : 15px;
}

.login .form-control:focus {
    background-color: #FFFFFF;
}

.login ::placeholder {
    color  : #C4C4C4;
    opacity: 1;
}

.login :-ms-input-placeholder {
    color: #C4C4C4;
}

.login ::-ms-input-placeholder {
    color: #C4C4C4;
}

/* DASHBOARD */

.cardDash {
    min-height: 290px;
}

.h_graphic {
    min-height: 150px;
}

.cards-padding .col-12:not(:last-child) {
    padding-bottom: 1rem;
}

.card.bg_image_quote {
    position: relative;
}

.card.bg_image_quote {
    background-image   : url(./../images/photos/QuoteImage.jpg);
    background-position: center;
    background-repeat  : no-repeat;
    background-size    : cover;
    position           : relative;
}

.card.bg_image_quote::before {
    content         : "";
    position        : absolute;
    top             : 0px;
    right           : 0px;
    bottom          : 0px;
    left            : 0px;
    background-color: rgba(245, 245, 245, 0.7);
}

.card.bg_image_quote p {
    position: relative;
}

/* toogle button */
/* The switch - the box around the slider */
.switch {
    position: relative;
    display : inline-block;
    width   : 60px;
    height  : 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width  : 0;
    height : 0;
}

/* The slider */
.slider {
    position          : absolute;
    cursor            : pointer;
    top               : 0;
    left              : 0;
    right             : 0;
    bottom            : 0;
    background-color  : #ccc;
    -webkit-transition: .4s;
    transition        : .4s;
}

.slider:before {
    position          : absolute;
    content           : "";
    height            : 26px;
    width             : 26px;
    left              : 4px;
    bottom            : 4px;
    background-color  : white;
    -webkit-transition: .4s;
    transition        : .4s;
}

input:checked+.slider {
    background-color: #FFA41E;
}

input:focus+.slider {
    box-shadow: 0 0 1px #FFA41E;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform    : translateX(26px);
    transform        : translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* ------------- */

.oi.oi-data-transfer-download::after {
    content: "Exportar";
}